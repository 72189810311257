import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import LayoutWrapper from 'containers/common/layout';

function PrivateRoutes() {
  const { isAuthenticated } = useSelector(state => state.auth);
  const { pathname, search } = useLocation();
  const univarsalLogin = localStorage.getItem('universalLogin');
  return isAuthenticated ? (
    <LayoutWrapper />
  ) : (
    <Navigate
      to={univarsalLogin ? '/auth/universal-login' : '/auth/login'}
      state={{ from: `${pathname}${search}` }}
    />
  );
}

export default PrivateRoutes;
