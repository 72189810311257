import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { Box, Typography, Button } from '@mui/material';

function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        p: 2,
        backgroundColor: '#fff',
      }}
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontSize: { xs: '6rem', md: '8rem' }, fontWeight: '600' }}
      >
        404
      </Typography>
      <Typography variant="h6" sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, mb: 4 }}>
        Oops! The page you’re looking for doesn’t exist.
      </Typography>
      <Button
        startIcon={<Home />}
        variant="contained"
        color="primary"
        className="rounded-pill"
        onClick={() => navigate('/')}
      >
        Go to Dashboard
      </Button>
    </Box>
  );
}

export default NotFound;
