import NotFound from 'containers/pages/not-found';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';

function ProtectedRoutes({ hasSupportTicketsAccess }) {
  if (!hasSupportTicketsAccess) {
    return <NotFound />;
  }
  return <Outlet />;
}
ProtectedRoutes.propTypes = {
  hasSupportTicketsAccess: PropTypes.bool.isRequired,
};

export default ProtectedRoutes;
